<template>
  <div v-if="$store.state.me.role === 'admin'" class="lsg-cabinet">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content">
      <div class="admin-container">
        <div class="admin-nav">
          <div
            class="btn"
            v-for="elem in nav"
            :class="{ 'btn--gray': !elem.active }"
            :key="elem.label"
            @click="onActive(elem)"
          >
            {{ elem.name }}
          </div>
        </div>
        <Carousel/>
        <AdminFaq/>
        <AdminEvents/>
        <Answer/>
      </div>
    </div>
  </div>
</template>

<script>

import Carousel from '@/views/admin/components/Carousel.vue'
import AdminFaq from '@/views/admin/components/AdminFaq.vue'
import AdminEvents from '@/views/admin/components/AdminEvents.vue'
import Answer from '@/views/admin/components/Answer.vue'

export default {
  name: 'Admin',
  components: {
    Carousel,
    AdminFaq,
    AdminEvents,
    Answer
  },
  data () {
    return {
      title: 'Админка',
      partners: [],
      selectUser: null,
      password: '123',
      email: null,
      nav: [
        {
          name: 'Карусель',
          active: true,
          label: 'carousel'
        },
        {
          name: 'FAQ',
          active: false,
          label: 'faq'
        },
        {
          name: 'События',
          active: false,
          label: 'events'
        },
        {
          name: 'Шаблоны ответов',
          active: false,
          label: 'answer'
        }
      ]
    }
  },
  beforeMount () {
    const tempNav = this.nav.map(item => {
      return {
        name: item.name,
        active: `#${item.label}` === window.location.hash || false,
        label: item.label
      }
    })
    this.nav = tempNav
  },
  methods: {
    onActive (elem) {
      const tempNav = this.nav.map(item => {
        return {
          name: item.name,
          active: elem.name === item.name || false,
          label: item.label
        }
      })
      this.nav = tempNav
      window.location.hash = elem.label
    }
  }
}
</script>

<style lang="stylus">
  .admin-container {
    padding 10px 60px

    +below(1192px) {
      padding 10px 0
    }
  }
  .admin-nav {
    display flex
    min-height 60px
    flex-direction row
    justify-content flex-start
    flex-wrap wrap

    > .btn {
      height 40px !important
      margin-bottom 20px
      margin-right 10px
    }
  }
</style>

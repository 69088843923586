<template>
  <ApolloQuery
    :query="require('@/graphql/queries/AllEvents.gql')"
    @result="doneLoadingEvents"
  >
    <template slot-scope="{ result: { loading, error } }">
      <div v-if="error" class="messages apollo error">
        <div class="messages__status error">{{ error.message }}</div>
      </div>
      <div v-if="loading && !error" class="messages__status loading">Идёт загрузка данных...</div>
      <div v-if="$route.hash === '#events'" class="admin-event">
        <div class="admin-event__nav">
          <div
            class="btn"
            v-for="elem in nav"
            :class="{ 'btn--gray': !elem.active }"
            :key="elem.label"
            @click="onActive(elem)"
          >
            {{ elem.name }}
          </div>
        </div>
        <div class="admin-event__body">
          <div v-if="selectNav.label === 'add'" class="admin-event_add">
            <label class="admin-event__select">
              <v-select
                appendToBody
                :options="sections"
                @input="onSectionAdd"
                placeholder="Выберите раздел"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label style="display: block; margin-top: 20px" class="admin-event__select">
              <v-select
                appendToBody
                :options="roles"
                @input="onRoleAdd"
                placeholder="Выберите роль"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <textarea v-model="addData.title" class="admin-event__textarea" placeholder="Заголовок"></textarea>
            <textarea v-model="addData.subtitle" class="admin-event__textarea" placeholder="Подзаголовок (необязательно)"></textarea>
            <textarea v-model="addData.text" class="admin-event__textarea" placeholder="Текст"></textarea>
            <div class="dropzone-container-event">
              <vue-dropzone
                id='dropzone'
                ref='dropzone'
                @vdropzone-files-added="filesPicked"
                @vdropzone-upload-progress="fileProgress"
                @vdropzone-error="fileError"
                @vdropzone-removed-file="fileRemove"
                @vdropzone-canceled="fileCancel"
                @vdropzone-queue-complete="queueComplete"
                :includeStyling="false"
                useCustomSlot
                :options="dropzoneOptions">
                <div class="dropzone-icon">
                  <i>
                    <PaperClip/>
                  </i>
                </div>
              </vue-dropzone>
              <div @click="addEvent" class="btn btn--green admin-event__button">Добавить</div>
            </div>
            <div id="dz-previews" class="dz-previews"></div>
          </div>
          <div v-if="selectNav.label === 'change'" class="admin-event__change">
            <label class="admin-event__select">
              <v-select
                appendToBody
                :options="allEvents.length > 0 ? allEvents.map(item => ({ value: item.id, label: item.title })): []"
                @input="onTitleChange"
                placeholder="Выберите новость"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label style="display: block; margin-top: 20px" class="admin-event__select">
              <v-select
                appendToBody
                :options="sections"
                @input="onTypeChange"
                :value="changeData.type"
                placeholder="Раздел"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label style="display: block; margin-top: 20px" class="admin-event__select">
              <v-select
                appendToBody
                :options="roles"
                @input="onRoleChange"
                :value="changeData.role"
                placeholder="Роль"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <textarea v-model="changeData.title" class="admin-event__textarea" placeholder="Заголовок"></textarea>
            <textarea v-model="changeData.subtitle" class="admin-event__textarea" placeholder="Подзаголовок"></textarea>
            <textarea v-model="changeData.text" class="admin-event__textarea" placeholder="Текст"></textarea>
            <div class="dropzone-container-event">
              <vue-dropzone
                id='dropzone'
                ref='dropzone'
                @vdropzone-files-added="filesPicked"
                @vdropzone-upload-progress="fileProgress"
                @vdropzone-error="fileError"
                @vdropzone-removed-file="fileRemove"
                @vdropzone-canceled="fileCancel"
                @vdropzone-queue-complete="queueComplete"
                :includeStyling="false"
                useCustomSlot
                :options="dropzoneOptions">
                <div class="dropzone-icon">
                  <i>
                    <PaperClip/>
                  </i>
                </div>
              </vue-dropzone>
              <div @click="changeEvent" class="btn btn--green admin-event__button">Изменить</div>
            </div>
            <div id="dz-previews" class="dz-previews"></div>
          </div>
          <div v-if="selectNav.label === 'delete'" class="admin-event__delete">
            <label class="admin-event__select">
              <v-select
                appendToBody
                :options="allEvents.length > 0 ? allEvents.map(item => ({ value: item.id, label: item.title })): []"
                @input="onTitleDelete"
                placeholder="Выберите новость"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <div @click="deleteEvent" class="btn btn--red admin-event__button">Удалить</div>
          </div>
          <!-- <TextConverter
            v-if="selectNav.label === 'add'"
            :data="{
              theme: addData.selectTheme || addData.newTheme,
              question: addData.question,
              answer: addData.answer,
              pay: addData.pay
            }"
            :purpose="'faq'"
          />
          <TextConverter
            v-if="selectNav.label === 'change'"
            :data="{
              theme: changeData.newTheme || changeData.selectTheme,
              question: changeData.question,
              answer: changeData.answer,
              pay: changeData.pay
            }"
            :purpose="'faq'"
          /> -->
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import axios from 'axios'
import DownIcon from '@/components/svg/DownIcon'
import ADD_EVENT from '@/graphql/mutations/AddEvent.gql'
import DELETE_EVENT from '@/graphql/mutations/DeleteEvent.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
// import TextConverter from '@/components/ui/TextConverter.vue'
import UPDATE_EVENT from '@/graphql/mutations/UpdateEvent.gql'
import PaperClip from '@/components/svg/PaperClip'
import vue2Dropzone from 'vue2-dropzone'

const _graphQlHelper = new GraphQLHelper()

const BASE_URL = process.env.VUE_APP_HTTP

const getTemplate = () => `
<div class="dz-preview dz-file-preview">
  <div class="dz-details">
    <div class="dz-size"><span data-dz-size></span></div>
    <div class="dz-filename"><span data-dz-name></span></div>
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <span class="dz-remove" data-dz-remove><a>Удалить</a></span>
</div>
`

export default {
  name: 'AdminEvents',
  components: {
    DownIcon,
    PaperClip,
    vueDropzone: vue2Dropzone
    // TextConverter
  },
  beforeMount () {
    this.selectNav = this.nav[0]
  },
  data () {
    return {
      files: [],
      convertData: {},
      allEvents: [],
      sections: [
        {
          value: 'crm',
          label: 'Релизы'
        },
        {
          value: 'news',
          label: 'Новости компании'
        },
        {
          value: 'offers',
          label: 'Специальные предложения'
        }
      ],
      roles: [
        {
          value: 'all',
          label: 'Все'
        },
        {
          value: 'manager',
          label: 'Сотрудники'
        },
        {
          value: 'partner',
          label: 'Клиенты'
        }
      ],
      nav: [
        {
          name: 'Добавить',
          active: true,
          label: 'add'
        },
        {
          name: 'Изменить',
          active: false,
          label: 'change'
        },
        {
          name: 'Удалить',
          active: false,
          label: 'delete'
        }
      ],
      addData: {
        section: null,
        title: null,
        subtitle: null,
        text: null,
        role: null
        // themeToggle: false,
      },
      changeData: {
        id: null,
        type: null,
        role: null,
        title: null,
        subtitle: null,
        text: null
      },
      deleteData: {
        id: null
      },
      selectNav: {},
      selectTheme: '',
      selectQuestion: '',
      question: '',
      answer: '',
      defaultFaq: [],
      faq: [],
      themes: [],
      questions: [],
      themeToggle: false,
      dropzoneOptions: {
        previewTemplate: getTemplate(),
        url: 'https://httpbin.org/post',
        parallelUploads: 1,
        maxFiles: 5,
        maxFilesize: 2, // 2Mb
        acceptedFiles: 'image/jpg, image/jpeg',
        previewsContainer: '#dz-previews'
      }
    }
  },
  methods: {
    validObj (obj) {
      let isValid = true
      const objectKeys = Object.keys(obj)
      objectKeys.forEach(key => {
        if (!obj[key] && key !== 'subtitle') isValid = false
      })
      return isValid
    },
    queueComplete () {
      if (this.files.length > 0) {
        this.files.forEach(elem => {
          if (elem.status !== 'success') {
            this.$notify({
              group: 'lsg-notify',
              text: 'Ошибка при загрузке файлов'
            })
          } else {
            this.$notify({
              group: 'lsg-notify',
              text: 'Файлы загружены'
            })
          }
        })
      }
    },
    fileCancel (file) {
      this.$refs.dropzone.removeAllFiles()
      this.$notify({
        group: 'lsg-notify',
        text: `Отмена загрузки файла ${file.name}`
      })
    },
    fileRemove (file, error, xhr) {
      const updateFiles = []
      this.files.forEach(elem => {
        if (elem.upload.uuid !== file.upload.uuid) {
          updateFiles.push(elem)
        }
      })
      this.files = updateFiles
    },
    fileError (file, message, xhr) {
      file.previewElement.style.setProperty('background-color', '#FF0000')
      this.$notify({
        group: 'lsg-notify',
        text: 'Файл не может быть загружен'
      })
    },
    fileProgress (file, progress, bytesSent) {
      file.previewElement.style.setProperty('--progress', progress + '%')
    },
    filesPicked (e) {
      this.files = [...this.files, ...e]
    },
    submitDropFile () {
      const url = `${BASE_URL}/files`
      let formData = new FormData()
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i]
        formData.append(file.upload.uuid, file)
      }
      axios.post(url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(() => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Файлы успешно отправлены'
        })
        this.$router.go()
      }).catch(() => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Ошибка при отправке файлов'
        })
      })
    },
    doneLoadingEvents ({ data, loading }) {
      this.allEvents = data.allEvents
    },
    async addEvent () {
      if (!this.validObj(this.addData)) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не все поля заполнены'
        })
        return
      }
      const filesArr = []
      if (this.files.length > 0) {
        this.files.forEach(elem => {
          if (elem.status !== 'success') {
            this.$notify({
              group: 'lsg-notify',
              text: 'Ошибка. Повторите отправку, дождавшись загрузки всех файлов'
            })
            throw new Error('Ошибка. Повторите отправку, дождавшись загрузки всех файлов')
          } else {
            filesArr.push(elem.upload.uuid)
          }
        })
      }
      const input = {
        type: this.addData.section,
        title: this.addData.title,
        subtitle: this.addData.subtitle,
        text: this.addData.text,
        role: this.addData.role,
        images: filesArr
      }
      this.$apollo.mutate({
        mutation: ADD_EVENT,
        variables: { input },
        update: (_, { data }) => {
          if (data) {
            try {
              if (this.files.length > 0) this.submitDropFile()
              this.$notify({
                group: 'lsg-notify',
                text: 'Успешно'
              })
              if (this.files.length === 0) this.$router.go()
            } catch (e) {
              this.$notify({
                group: 'lsg-notify',
                text: e
              })
            }
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    changeEvent () {
      if (!this.validObj(this.changeData)) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не все поля заполнены'
        })
        return
      }
      const filesArr = []
      if (this.files.length > 0) {
        this.files.forEach(elem => {
          if (elem.status !== 'success') {
            this.$notify({
              group: 'lsg-notify',
              text: 'Ошибка. Повторите отправку, дождавшись загрузки всех файлов'
            })
            throw new Error('Ошибка. Повторите отправку, дождавшись загрузки всех файлов')
          } else {
            filesArr.push(elem.upload.uuid)
          }
        })
      }
      const input = {
        id: this.changeData.id,
        type: this.changeData.type,
        title: this.changeData.title,
        subtitle: this.changeData.subtitle,
        text: this.changeData.text,
        role: this.changeData.role
      }
      if (filesArr.length > 0) input['images'] = filesArr
      this.$apollo.mutate({
        mutation: UPDATE_EVENT,
        variables: { input },
        update: (_, { data }) => {
          if (data) {
            if (this.files.length > 0) this.submitDropFile()
            this.$notify({
              group: 'lsg-notify',
              text: 'Успешно'
            })
            if (this.files.length === 0) this.$router.go()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    deleteEvent () {
      this.$apollo.mutate({
        mutation: DELETE_EVENT,
        variables: {
          id: this.deleteData.id
        },
        update: (_, { data }) => {
          if (data) {
            this.$notify({
              group: 'lsg-notify',
              text: `Успешно`
            })
            this.$router.go()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    onActive (elem) {
      this.selectNav = elem
      const tempNav = this.nav.map(item => {
        return {
          name: item.name,
          active: elem.name === item.name || false,
          label: item.label
        }
      })
      this.nav = tempNav
    },
    onSectionAdd (select) {
      this.addData.section = select.value
    },
    onRoleAdd (select) {
      this.addData.role = select.value
    },
    onTitleDelete (select) {
      this.deleteData.id = select.value
    },
    onTitleChange (select) {
      this.changeData.id = select.value
      let dataToChange = null
      this.allEvents.forEach(elem => {
        if (elem.id === select.value) {
          dataToChange = elem
        }
      })
      this.changeData.type = dataToChange.type
      this.changeData.role = dataToChange.role
      this.changeData.title = dataToChange.title
      this.changeData.subtitle = dataToChange.subtitle
      this.changeData.text = dataToChange.text
    },
    onTypeChange (select) {
      this.changeData.type = select.value
    },
    onRoleChange (select) {
      this.changeData.role = select.value
    }
  }
}
</script>

<style lang="stylus">
  .dropzone-container-event {
    min-width 40px
    min-height 40px
    margin 15px 0 0 0
    display flex
    flex-direction row
    justify-content space-between
  }
  #dropzone {
    min-height 40px
    height 100%
  }
  .dz-message {
    height: 40px !important
  }

  .dropzone-icon {
    display flex
    justify-content center
    align-items center
    text-align center
    background: $white;
    border: 1px dashed $c4E555A;
    box-sizing: border-box;
    border-radius: 10px;
    padding-left 10px
    max-width 350px
    width 60px
    height 40px
    cursor pointer

    &--full {
      width 100%
      margin-bottom 30px
    }

    i {
      width 22px
      height 22px
      display flex
      align-items center
      justify-content center
      margin-right 12px

      svg {
        width 100%
        height 100%
      }
    }
  }
  .admin-event {
    display flex
    flex-direction row

    +below(768px) {
      flex-direction column
    }

    &__nav {
      display flex
      flex-direction column
      height 150px
      justify-content space-between

      +below(768px) {
        flex-direction row
        height 30px
        max-width 400px
        justify-content space-between
        margin-bottom 30px
      }
    }

    &__body {
      display flex
      margin-left 20px
      flex-direction row
      min-height 280px
      width 80%
      justify-content space-between
      +below(1192px) {
        flex-direction column
      }
      +below(768px) {
        margin 20px auto
      }
    }

    &__select {
      min-width 300px
    }

    &__add,
    &__change {
      max-width 40%

      +below(1192px) {
        max-width 100%
      }
    }

    &__delete {
      display flex
      flex-direction column
      justify-content space-between
      height 150px
    }

    &__textarea {
      margin-top 20px
    }

    &__button {
      width 0 !important
      min-width 150px
      white-space nowrap
    }
  }

  .btn {
    &--red {
      background $red !important
      color $white !important
      border-color $red !important
    }

    &--green {
      background $anothergreen !important
      color $white !important
      border-color $anothergreen !important
    }
  }
</style>

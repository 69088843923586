<template>
  <ApolloQuery
    :query="require('@/graphql/queries/AllFaqPopular.gql')"
    @result="doneLoadingFaq"
  >
    <template slot-scope="{ result: { loading, error } }">
      <div v-if="error" class="messages apollo error">
        <div class="messages__status error">{{ error.message }}</div>
      </div>
      <div v-if="loading && !error" class="messages__status loading">Идёт загрузка данных...</div>
      <div v-if="$route.hash === '#faq'" class="admin-faq">
        <div class="admin-faq__nav">
          <div
            class="btn"
            v-for="elem in nav"
            :class="{ 'btn--gray': !elem.active }"
            :key="elem.label"
            @click="onActive(elem)"
          >
            {{ elem.name }}
          </div>
        </div>
        <div class="admin-faq__body">
          <div v-if="selectNav.label === 'add'" class="admin-faq__add">
            <label class="admin-faq__select">
              <v-select
                appendToBody
                :options="addData.themes"
                @input="onThemeChange"
                placeholder="Выберите тему"
              >
                <template slot="no-options">Нет результатов <a style="cursor: pointer;" @click="addData.themeToggle = true">(Создать новую тему?)</a></template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <textarea v-if="addData.themeToggle" v-model="addData.newTheme" class="admin-faq__textarea" placeholder="Тема"></textarea>
            <textarea v-model="addData.question" class="admin-faq__textarea" placeholder="Вопрос"></textarea>
            <textarea v-model="addData.answer" class="admin-faq__textarea" placeholder="Ответ"></textarea>
            <input type="text" v-model="addData.pay" placeholder="Цена (если услуга платная)">
            <div @click="addFaq" class="btn btn--green admin-faq__button">Добавить</div>
          </div>
          <div v-if="selectNav.label === 'change'" class="admin-faq__change">
            <label v-if="!changeData.selectQuestion" class="admin-faq__select">
              <v-select
                appendToBody
                :options="changeData.themes"
                @input="onThemeChange"
                placeholder="Выберите тему"
                :enable="true"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <textarea v-model="changeData.newTheme" v-if="changeData.selectTheme" class="admin-faq__textarea" placeholder="Введите тему"></textarea>
            <div v-if="!changeData.selectTheme" style="margin-top: 20px">
              <label class="admin-faq__select">
                <v-select
                  appendToBody
                  :options="changeData.questions.map(item => ({ value: item.id, label: item.question }))"
                  @input="onQuestionChange"
                  placeholder="Или выберите вопрос"
                >
                  <template slot="no-options">Нет результатов</template>
                  <template #open-indicator="{ attributes }">
                    <div v-bind="attributes">
                      <DownIcon/>
                    </div>
                  </template>
                </v-select>
              </label>
              <div v-if="changeData.selectQuestion">
                <textarea v-model="changeData.question" class="admin-faq__textarea" placeholder="Вопрос"></textarea>
                <textarea v-model="changeData.answer" class="admin-faq__textarea" placeholder="Ответ"></textarea>
                <input v-model="changeData.pay" type="text" placeholder="Цена (если услуга платная)">
              </div>
            </div>
            <div @click="changeFaq" class="btn btn--green admin-faq__button">Изменить</div>
          </div>
          <div v-if="selectNav.label === 'delete'" class="admin-faq__delete">
            <label class="admin-faq__select">
              <v-select
                appendToBody
                :options="deleteData.themes"
                @input="onThemeChange"
                placeholder="Выберите тему"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label class="admin-faq__select">
              <v-select
                appendToBody
                :options="deleteData.questions.map(item => ({ value: item.id, label: item.question }))"
                @input="onQuestionChange"
                placeholder="Или выберите вопрос"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <div @click="deleteFaq" class="btn btn--red admin-faq__button">Удалить {{ deleteData.selectQuestion ? 'вопрос' : deleteData.selectTheme ? 'тему' : '' }}</div>
          </div>
          <TextConverter
            v-if="selectNav.label === 'add'"
            :data="{
              theme: addData.selectTheme || addData.newTheme,
              question: addData.question,
              answer: addData.answer,
              pay: addData.pay
            }"
            :purpose="'faq'"
          />
          <TextConverter
            v-if="selectNav.label === 'change'"
            :data="{
              theme: changeData.newTheme || changeData.selectTheme,
              question: changeData.question,
              answer: changeData.answer,
              pay: changeData.pay
            }"
            :purpose="'faq'"
          />
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>

import DownIcon from '@/components/svg/DownIcon'
import ADD_FAQ from '@/graphql/mutations/AddFaq.gql'
import DELETE_FAQ from '@/graphql/mutations/DeleteFaq.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import TextConverter from '@/components/ui/TextConverter.vue'
import UPDATE_FAQ from '@/graphql/mutations/UpdateFaqPopular.gql'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'AdminFaq',
  components: {
    DownIcon,
    TextConverter
  },
  watch: {
    'changeData.selectQuestion': function () {
      this.defaultFaq.forEach(elem => {
        if (this.changeData.selectQuestion && elem.question === this.changeData.selectQuestion.label) {
          this.changeData.question = elem.question
          this.changeData.answer = elem.answer
          this.changeData.pay = elem.pay
          this.changeData.selectId = elem.id
        }
      })
    }
  },
  beforeMount () {
    this.selectNav = this.nav[0]
  },
  data () {
    return {
      convertData: {},
      nav: [
        {
          name: 'Добавить',
          active: true,
          label: 'add'
        },
        {
          name: 'Изменить',
          active: false,
          label: 'change'
        },
        {
          name: 'Удалить',
          active: false,
          label: 'delete'
        }
      ],
      addData: {
        themes: [],
        selectTheme: '',
        newTheme: '',
        question: '',
        answer: '',
        themeToggle: false,
        pay: ''
      },
      changeData: {
        selectId: '',
        themes: [],
        selectTheme: '',
        selectQuestion: null,
        newTheme: '',
        question: '',
        answer: '',
        questionToggle: false,
        pay: ''
      },
      deleteData: {
        themes: [],
        questions: [],
        selectTheme: '',
        selectQuestion: ''
      },
      selectNav: {},
      selectTheme: '',
      selectQuestion: '',
      question: '',
      answer: '',
      defaultFaq: [],
      faq: [],
      themes: [],
      questions: [],
      themeToggle: false
    }
  },
  methods: {
    doneLoadingFaq ({ data, loading }) {
      let themes = data.allFaqPopular.map(elem => {
        return elem.theme
      })
      this.defaultFaq = data.allFaqPopular
      themes = [...new Set(themes)]
      this.deleteData.themes = this.changeData.themes = this.addData.themes = themes

      let questions = data.allFaqPopular.map(elem => {
        return {
          id: elem.id,
          question: elem.question
        }
      })
      questions = [...new Set(questions)]

      this.deleteData.questions = this.changeData.questions = questions
      let obj = themes.map(elem => (
        {
          theme: elem,
          list: []
        }
      ))
      data.allFaqPopular.forEach(faqElem => {
        obj.forEach(objElem => {
          if (faqElem.theme === objElem.theme) {
            objElem.list.push(
              {
                expanded: false,
                question: faqElem.question,
                answer: faqElem.answer,
                pay: faqElem.pay
              }
            )
          }
        })
      })
      this.faq = obj
    },
    async addFaq () {
      if ((this.addData.selectTheme !== '' || this.addData.newTheme !== '') && this.addData.question !== '' && this.addData.answer !== '') {
        const theme = this.addData.newTheme && this.addData.themeToggle ? this.addData.newTheme : this.addData.selectTheme
        this.$apollo.mutate({
          mutation: ADD_FAQ,
          variables: {
            theme: theme,
            question: this.addData.question,
            answer: this.addData.answer,
            pay: this.addData.pay !== '' ? this.addData.pay : null
          },
          update: (_, { data }) => {
            if (data) {
              this.addData.selectTheme = this.addData.newTheme = this.addData.question = this.addData.answer = this.addData.pay = ''
              this.$notify({
                group: 'lsg-notify',
                text: 'Успешно'
              })
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      } else {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не все поля заполнены'
        })
      }
    },
    changeFaq () {
      if (this.changeData.selectQuestion) {
        this.$apollo.mutate({
          mutation: UPDATE_FAQ,
          variables: {
            id: this.changeData.selectId,
            question: this.changeData.question,
            answer: this.changeData.answer,
            pay: this.changeData.pay !== '' ? this.changeData.pay : null
          },
          update: (_, { data }) => {
            this.$router.go()
            if (data) {
              this.$notify({
                group: 'lsg-notify',
                text: 'Успешно'
              })
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      } else if (this.changeData.selectTheme) {
        this.$apollo.mutate({
          mutation: UPDATE_FAQ,
          variables: {
            oldTheme: this.changeData.selectTheme,
            theme: this.changeData.newTheme || this.changeData.selectTheme
          },
          update: (_, { data }) => {
            if (data) {
              this.$router.go()
              this.$notify({
                group: 'lsg-notify',
                text: 'Успешно'
              })
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      } else {
        this.$notify({
          group: 'lsg-notify',
          text: 'Ошибка'
        })
      }
    },
    deleteFaq () {
      this.$apollo.mutate({
        mutation: DELETE_FAQ,
        variables: {
          theme: this.deleteData.selectTheme || null,
          questionId: this.deleteData.selectQuestion ? this.deleteData.selectQuestion.value : null
        },
        update: (_, { data }) => {
          if (data) {
            this.deleteData.selectTheme = ''
            this.deleteData.selectQuestion = {}
            this.$notify({
              group: 'lsg-notify',
              text: `Успешно. Удалены ${data.deleteFaqPopular.deletedCount} вопросов`
            })
            this.$router.go()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    onActive (elem) {
      this.selectNav = elem
      const tempNav = this.nav.map(item => {
        return {
          name: item.name,
          active: elem.name === item.name || false,
          label: item.label
        }
      })
      this.nav = tempNav
    },
    onThemeChange (select) {
      this.addData.themeToggle = false
      this.deleteData.selectTheme = select
      this.addData.selectTheme = select
      this.changeData.selectTheme = select
    },
    onQuestionChange (select) {
      this.deleteData.selectQuestion = select
      this.changeData.selectQuestion = select
    }
  }
}
</script>

<style lang="stylus">
  .admin-faq {
    display flex
    flex-direction row

    +below(768px) {
      flex-direction column
    }

    &__nav {
      display flex
      flex-direction column
      height 150px
      justify-content space-between

      +below(768px) {
        flex-direction row
        height 30px
        max-width 400px
        justify-content space-between
        margin-bottom 30px
      }
    }

    &__body {
      display flex
      margin-left 20px
      flex-direction row
      min-height 280px
      width 80%
      justify-content space-between
      +below(1192px) {
        flex-direction column
      }
      +below(768px) {
        margin 20px auto
      }
    }

    &__select {
      min-width 300px
    }

    &__add,
    &__change {
      max-width 40%

      +below(1192px) {
        max-width 100%
      }
    }

    &__delete {
      display flex
      flex-direction column
      justify-content space-between
      height 150px
    }

    &__textarea {
      margin-top 20px
    }

    &__button {
      margin-top 20px !important
      width 0 !important
      min-width 150px
      white-space nowrap
    }
  }

  .btn {
    &--red {
      background $red !important
      color $white !important
      border-color $red !important
    }

    &--green {
      background $anothergreen !important
      color $white !important
      border-color $anothergreen !important
    }
  }
</style>

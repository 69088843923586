<template>
  <div v-if="$route.hash === '#carousel'" class="carousel">
    <label class="carousel__select">
      <v-select
        appendToBody
        :options="$store.state.allUsers.filter(user => !user.banned).map(item => ({ value: item.id, label: item.name }))"
        @input="onUserChange"
        placeholder="Выберите сотрудника"
      >
        <template slot="no-options">Нет результатов</template>
        <template #open-indicator="{ attributes }">
          <div v-bind="attributes">
            <DownIcon/>
          </div>
        </template>
      </v-select>
    </label>
    <label class="carousel__select">
      <v-select
        appendToBody
        :options="partners.map(item => ({ value: item.id, label: item.inn, name: item.name }))"
        @input="onUserChange"
        placeholder="Напишите ИНН клиента"
      >
        <template v-slot:option="option">{{ option.name }}</template>
        <template slot="no-options">Нет результатов</template>
        <template #open-indicator="{ attributes }">
          <div v-bind="attributes">
            <DownIcon/>
          </div>
        </template>
      </v-select>
    </label>
    <div @click="changeUser" class="btn">Перейти</div>
  </div>
</template>

<script>

import ALL_PARTNERS from '@/graphql/queries/AllPartners.gql'
import USER_FOR_ADMIN from '@/graphql/queries/UserForAdmin.gql'
import LOGIN_USER from '@/graphql/mutations/LoginUser.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import DownIcon from '@/components/svg/DownIcon'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Carousel',
  components: {
    DownIcon
  },
  data () {
    return {
      partners: [],
      selectUser: null,
      password: '123',
      email: null
    }
  },
  beforeCreate () {
    this.$apollo.mutate({
      mutation: ALL_PARTNERS,
      variables: {},
      update: (_, { data }) => {
        if (data) {
          this.partners = data.allPartners
        }
      }
    }).catch(error => {
      _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
        this.$notify({
          group: 'lsg-notify',
          text: item
        })
      })
    })
  },
  methods: {
    onUserChange (select) {
      this.selectUser = select
    },
    changeUser () {
      localStorage.setItem('admin-token', localStorage.getItem('apollo-token'))
      this.$apollo.mutate({
        mutation: USER_FOR_ADMIN,
        variables: {
          id: this.selectUser.value
        },
        update: (_, { data }) => {
          if (data) {
            this.email = data.userForAdmin.email
            this.loginUser()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    async loginUser () {
      if (!this.email) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Введите email' + (!this.password ? ' и пароль' : '')
        })
        return
      }
      if (!this.password) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Введите пароль'
        })
        return
      }
      await this.$apollo.mutate({
        mutation: LOGIN_USER,
        variables: {
          email: this.email,
          password: this.password
        },
        update: (store, { data: { loginUser } }) => {
          localStorage.setItem('myEmail', this.$store.state.me.email)
          this.$store.dispatch({
            type: 'setAuthToken',
            token: loginUser
          }).then(() => {
            this.$router.push('/')
            this.$router.go()
          })
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    }
  }
}
</script>

<style lang="stylus">
  .carousel {
    min-width 100px
    width 50%
    min-height 400px

    +below(1192px) {
      width 100%
    }

    > .carousel__select {
      display flex
      align-items center
      justify-content flex-start
      margin 5px 0

      > div > div {
        height 35px
      }
    }
  }
</style>

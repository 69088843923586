<template>
  <div class="text-converter">
    <div class="text-converter__header"><h2>Предпросмотр</h2></div>
    <div class="text-converter__body">
      <div class="faq__list" style="width: 100%; padding: 0;">
        <div class="faq__theme">
          <h2 v-html="data.theme || 'Выберите тему'"></h2>
          <div class="faq__item">
            <i class="faq__item__state">
              <MinusIco/>
            </i>
            <h3 class="faq__item__question"><span v-html="data.question || 'Напишите вопрос'"></span> <span v-if="data.pay"><RubleIco /></span></h3>
            <div class="faq__item__answer" v-html="data.answer || 'Напишите ответ'"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-converter__footer"></div>
  </div>
</template>

<script>

import MinusIco from '@/components/svg/minus'
import RubleIco from '@/components/svg/Ruble'

export default {
  name: 'TextConverter',
  components: {
    MinusIco,
    RubleIco
  },
  props: {
    data: Object,
    purpose: String,
    view: Boolean
  },
  data () {
    return {
      input: {
        data: this.data || {},
        purpose: this.purpose || 'default',
        view: this.view || false
      },
      output: ''
    }
  },
  computed: {
    outputModel: {
      get () {
        return this.output
      },
      set (value) {
        this.output = value
      }
    }
  }
}
</script>

<style lang="stylus">
  .text-converter {
    display flex
    flex-direction column
    width 50%
    min-height 200px
    border 1px solid $dark
    border-radius 20px
    margin-top 10px

    +below(1192px) {
      width 100%
      margin-top 20px
    }

    &__header {
      display flex
      justify-content center
      align-items center
      margin-top -17px

      > h2 {
        background #f7f9fc
        padding 5px 20px
      }
    }

    &__body {
      padding 20px
      display flex
      flex-direction column
      justify-content center
      align-items flex-start
      height 100%
      white-space pre-wrap
    }
  }

  .faq {
    &__list {
      display flex
      flex-direction column
      align-items flex-start
      margin -8px 0
      padding 10px 60px

      +below(470px) {
        padding 10px 10px
      }
    }

    &__theme {
      display flex
      width 100%
      flex-direction column
      align-items flex-start

      &:not(:last-child) {
        margin-bottom 30px
      }
    }

    &__item {
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      background: $white;
      border: 1px solid rgba($darkgray, 0.3);
      box-sizing: border-box;
      border-radius: 10px;
      padding 40px 80px 40px 40px
      width 100%
      margin 8px 0
      position relative
      cursor pointer

      &:hover {
        box-shadow: 0 10px 30px rgba($darker, 0.17)
      }

      &__state {
        absolute right 40px top 40px bottom
        width 24px
        height 24px
        display flex
        align-items center
        justify-content center
        flex-shrink 0

        svg {
          width 100%
          height 100%

          path {
            stroke $c7A
          }
        }
      }

      &__question {
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        margin 0
        color $orange

        +below(470px) {
          font-size 14px
        }

        +below(375px) {
          font-size 12px
        }
      }

      &__pay {
        width 100%
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        margin-top 20px
        color $dark
        white-space pre-wrap
      }

      &__answer {
        width 100%
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        margin-top 20px
        color $dark
        white-space pre-wrap

        > a {
          color $orange
          z-index 2

          &:hover {
            text-shadow 1px 1px 2px $orange
          }
        }

        +below(470px) {
          font-size 14px
        }

        +below(375px) {
          font-size 12px
        }

        ^[0]:hover {
          color $dark
        }
      }
    }
  }
</style>

<template>
  <div v-if="$route.hash === '#answer'" class="answer">
    <div class="answer__nav">
      <div
        class="btn"
        v-for="elem in nav"
        :class="{ 'btn--gray': !elem.active }"
        :key="elem.label"
        @click="onActive(elem)"
      >
        {{ elem.name }}
      </div>
    </div>
    <div class="answer__body">
      <div v-if="selectNav.label === 'add'" class="answer__add">
        <label class="answer__select">
          <v-select
            appendToBody
            :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))"
            @input="onUserChange"
            placeholder="Выберите сотрудника"
          >
            <template slot="no-options">Нет результатов</template>
            <template #open-indicator="{ attributes }">
              <div v-bind="attributes">
                <DownIcon/>
              </div>
            </template>
          </v-select>
        </label>
        <input type="text" v-model="newTitle" placeholder="Заголовок">
        <textarea v-model="newMessage" placeholder="Сообщение"></textarea>
        <div @click="addMessage" class="btn btn--green">Добавить</div>
      </div>
      <div v-if="selectNav.label === 'change'" class="answer__change">
        <label class="answer__select">
          <v-select
            appendToBody
            :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))"
            @input="onUserChange"
            placeholder="Выберите сотрудника"
          >
            <template slot="no-options">Нет результатов</template>
            <template #open-indicator="{ attributes }">
              <div v-bind="attributes">
                <DownIcon/>
              </div>
            </template>
          </v-select>
        </label>
        <label v-if="selectUser && Object.keys(selectUser).length !== 0" class="answer__select">
          <v-select
            appendToBody
            :options="messages.map(item => ({ value: item.id, label: item.title, type: item.type, message: item.message }))"
            @input="onTitleChange"
            placeholder="Заголовок"
          >
            <template slot="no-options">Нет результатов</template>
            <template #open-indicator="{ attributes }">
              <div v-bind="attributes">
                <DownIcon/>
              </div>
            </template>
          </v-select>
        </label>
        <div v-if="selectUser && Object.keys(selectUser).length !== 0 && selectTitle && Object.keys(selectTitle).length !== 0" class="answer__input">
          <input type="text" v-model="updateTitle" placeholder="Заголовок">
          <textarea v-model="updateMessage" placeholder="Сообщение"></textarea>
          <div class="answer__buttons">
            <div @click="changeMessage" class="btn btn">Изменить</div>
            <div @click="deleteMessage" class="btn btn--red">Удалить</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ALL_MESSAGE_TEMPLATES from '@/graphql/queries/AllMessageTemplates.gql'
import ADD_MESSAGE_TEMPLATE from '@/graphql/mutations/AddMessageTemplate.gql'
import UPDATE_MESSAGE_TEMPLATE from '@/graphql/mutations/UpdateMessageTemplate.gql'
import DELETE_MESSAGE_TEMPLATE from '@/graphql/mutations/DeleteMessageTemplate.gql'
import DownIcon from '@/components/svg/DownIcon'
import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Answer',
  components: {
    DownIcon
  },
  data () {
    return {
      selectUser: {},
      selectTitle: {},
      messages: [],
      updateTitle: '',
      updateMessage: '',
      newTitle: '',
      newMessage: '',
      toggleAdd: false,
      selectNav: {},
      nav: [
        {
          name: 'Добавить',
          active: true,
          label: 'add'
        },
        {
          name: 'Изменить',
          active: false,
          label: 'change'
        }
      ]
    }
  },
  beforeMount () {
    this.selectNav = this.nav[0]
  },
  beforeUpdate () {
    if (!this.selectUser || !this.selectTitle) this.toggleAdd = false
  },
  watch: {
    selectUser: function () {
      if (typeof this.selectUser === 'object' && this.selectUser !== null) {
        this.$apollo.mutate({
          mutation: ALL_MESSAGE_TEMPLATES,
          variables: {
            responsible_id: this.selectUser.value
          },
          update: (_, { data }) => {
            if (data) {
              this.messages = data.allMessageTemplates
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      }
    }
  },
  methods: {
    onUserChange (select) {
      this.selectUser = select
    },
    onTitleChange (select) {
      this.selectTitle = select
      this.updateTitle = select.label
      this.updateMessage = select.message
    },
    onActive (elem) {
      this.selectNav = elem
      const tempNav = this.nav.map(item => {
        return {
          name: item.name,
          active: elem.name === item.name || false,
          label: item.label
        }
      })
      this.nav = tempNav
    },
    addMessage () {
      if (this.selectUser && this.selectUser.value !== '' && this.newTitle !== '' && this.newMessage !== '') {
        this.$apollo.mutate({
          mutation: ADD_MESSAGE_TEMPLATE,
          variables: {
            title: this.newTitle,
            message: this.newMessage,
            responsible_id: this.selectUser.value,
            type: 'appeal'
          },
          update: (_, { data }) => {
            if (data) {
              this.$notify({
                group: 'lsg-notify',
                text: 'Успешно'
              })
              this.$router.go()
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      } else {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не все поля заполнены'
        })
      }
    },
    changeMessage () {
      if (this.selectTitle && this.selectTitle.value) {
        const input = {
          id: this.selectTitle.value
        }
        if (this.updateTitle !== '') input.title = this.updateTitle
        if (this.updateMessage !== '') input.message = this.updateMessage
        this.$apollo.mutate({
          mutation: UPDATE_MESSAGE_TEMPLATE,
          variables: input,
          update: (_, { data }) => {
            if (data) {
              this.$notify({
                group: 'lsg-notify',
                text: 'Успешно'
              })
              this.$router.go()
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      } else {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не все поля заполнены'
        })
      }
    },
    deleteMessage () {
      if (this.selectTitle && this.selectTitle.value) {
        this.$apollo.mutate({
          mutation: DELETE_MESSAGE_TEMPLATE,
          variables: {
            id: this.selectTitle.value
          },
          update: (_, { data }) => {
            if (data) {
              this.$notify({
                group: 'lsg-notify',
                text: 'Успешно'
              })
              this.$router.go()
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      } else {
        this.$notify({
          group: 'lsg-notify',
          text: 'Ошибка'
        })
      }
    }
  }
}
</script>

<style lang="stylus">
  .answer {
    min-width 100px
    width 50%
    min-height 400px
    display flex
    flex-direction row

    &__nav {
      display flex
      flex-direction column
      height 90px
      justify-content space-between
      min-width 114px
    }

    +below(1192px) {
      width 100%
      flex-direction column

      &__nav {
        flex-direction row
        height 40px
        max-width 250px
        margin-bottom 10px
      }
    }

    &__add {
      margin-left 20px
      min-height 300px

      > input, textarea {
        margin-bottom 5px
      }

      // > input {
      //   margin-top 10px
      // }
    }

    &__change {
      margin-left 20px
      min-height 300px
    }

    &__input {
      > input {
        margin-bottom 5px
      }
    }

    &__select {
      display flex
      align-items center
      justify-content flex-start
      margin 5px 0

      > div > div {
        height 35px
      }
    }

    &__buttons {
      display flex
      width 100%
      justify-content space-between
    }
  }
</style>
